.center-login-block {
    display: inline-block;
    float: none;
    text-align: left;
    margin-top: 20vh;
}

.navbar-nav > li > a {
    padding: 15px 25px;
}
/* Блок смены языков */
.btn-group img {
    position: relative;
    top: -3px;
}
.current-language {
    display: inline-block;
}

table .btn .fa {
  line-height: 1.45555;
}

/* /Блок смены языков */

/* Редактор меню */
.menu-empty .placeholder {
    outline: 1px dashed red;
}

.placeholder {
    height: 16px;
    outline: 1px dashed #4183C4;
    border: none !important;
}
.placeholder:before {
    border: none !important;
}
.menu {
    padding-left: 15px;
    list-style: none;
    counter-reset: li;
}
.menu-item {
    cursor: pointer;
    color: #1873b4;
    margin: 6px;
}
.menu-empty {
    min-height: 5px;
}
.sub-menu .fa-angle-down,
.sub-menu .fa-angle-right {
    position: relative;
    top: 4px;
    padding: 0;
    margin: 0;
}
.sub-menu .fa-angle-right {
    text-align: center;
    width: 11px;
}
.fake-width {
    width: 8px;
    display: inline-block;
}
/* /Редактор меню */

.customize-tab .nav-pills > li {
  display: inline-block;
  width: auto;
  float: none;
}

.customize-tab .nav > li > a {
  border: 0;
  border-top-color: none;
  padding: 3px 7px;
}

.flex { display: flex; }
.flex > div { flex: 1; padding: 0; }
.flex > .price-type, .flex > .btn-wrap { flex: 0 auto; }
.flex > .btn-wrap > .btn { border-radius: 0; }

.has-error-label { background-color: #dd4b39; }

.office-map { height: 360px; }

@media(max-width: 767px) {
  .office-map { height: 200px; margin-bottom: 15px; }
}

.my-room-map { height: 500px; }

@media(max-width: 767px) {
  .my-room-map { height: 200px; }
}

.breadcrumb { background-color: #ecf0f5; }

.dataTable {
  font-size: 14px;
  font-weight: 400;
}

.dataTable select.form-control,
.dataTable input.form-control {
  height: 28px;
  padding: 3px 6px;
  width: 100%;
}

.dataTable input.form-control.id {
  width: 30px;
  padding: 0;
  text-align: center;
}

.nav-pills > li.active > a {
  font-weight: 300;
}

/*Карточка превью изображения продукции*/
.file-preview-body { background-color: #fff; }
.file-preview-body .file-preview-frame { margin: 0; margin-right: 5px; }
.file-preview-frame > img { width: auto; height: 140px; }
.file-preview-frame .file-thumbnail-footer { margin-top: 5px; display: block; }
.file-thumbnail-footer > div { line-height: 22px; margin: 0 5px; }

.row-none-margin {
  margin: 0;
}

.card-price {
  background: #fff3b5 none repeat scroll 0 0;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.5em;
  padding: 7px 7px 5px;
  font-size: 1.38462em;
  font-weight: 600;
  line-height: 1em;
  white-space: nowrap;
  width: auto;
}

.card-price .card-price-uah {
  font-size: 0.66667em;
  line-height: 1em;
  font-weight: 300;
}

.shopping-cart input {
  border: 1px solid #c2c2cc;
  border-radius: 3px;
  box-shadow: 3px 3px 1px 0 #eeeeef inset;
  display: inline-block;
  font-size: 18px;
  height: 30px;
  resize: none;
  text-align: center;
  vertical-align: bottom;
  width: 45px;
}

@media(max-width: 767px) {
  .shopping-cart div:first-child { text-align: right; }
}

.shopping-product tbody td {
  vertical-align: middle !important;
}

.shopping-type-product select {
  width: auto !important;
}

.cart-quantity { text-align: center; }
@media(max-width: 767px) {
  .cart-quantity { text-align: right; margin-bottom: 8px; margin-top: 10px; }
}

.in-shoping-cart { text-align: right; }
.in-shoping-cart button { position: relative; }
.in-shoping-cart .fa {
  position: absolute;
  top: 3px;
  left: 4px;
  font-size: 25px;
}

.cart-quantity-minus,
.cart-quantity-plus { padding: 0; }

.cart-quantity-minus:hover,
.cart-quantity-plus:hover { color: #d9534f; }

.cart-quantity-minus i,
.cart-quantity-plus i { font-size: 18px; }

.padding-top-30 { padding-top: 30px; }


.load-data {
  cursor: pointer;
}

div.dataTables_length select {
  font-size: 14px;
  height: 28px;
  padding: 3px 12px;
}

/*обрезка текста*/
.cute-text {
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  text-overflow: ellipsis; /* Добавляем многоточие */
}

.sum-price {
  display: inline-block;
}

.product-price {
  width: 50px;
  border: 0;
  background: #fff3b5 none repeat scroll 0 0;
  display: inline-block;
  padding: 0 6px;
  border-radius: 3px;
  text-align: center;
  font-weight: 600;
}

.card-white-price {
  font-size: 16px;
  line-height: 30px;
}


.card-price-text {
  position: relative;
  top: -15px;
  display: inline;
  font-size: 18px;
}

.card-price div {
  display: inline-block;
}

.total-price { display: inline-block; }

.card-price,
.card-white-price {
  border-radius: 4px;
  display: inline-block;
  font-size: 18px;
  height: 30px;
}
.card-price {
  background: #fff3b5 none repeat scroll 0 0;
  padding: 7px 7px 5px;
  line-height: 18px;
  font-weight: 600;
  width: auto;
}

.card-white-price {
  line-height: 34px;
}

.card-price .card-price-uah,
.card-white-price .card-price-uah {
  font-size: 0.77777em;
  line-height: 1em;
  font-weight: 300;
}

.products-cards .panel .product-title,
.products-cards .panel .card-price,
.shopping-cart-block .card-price-block .card-price {
  border-radius: 0;
  font-size: 16px;
  padding: 9px 15px;
  line-height: 1.42857;
  vertical-align: middle;
  height: auto;
}
@media(max-width: 767px) {
  .products-cards .panel .product-title,
  .products-cards .panel .card-price,
  .shopping-cart-block .card-price-block .card-price {
    font-size: 14px; padding: 7px 10px;
  }
}

.products-cards .panel .text-black-h3 {
  line-height: 1;
}

.products-cards .panel .card-price,
.products-cards .panel .card-sum-price {
  height: auto;
}


.view-order {
  cursor: pointer;
}

.ui-tooltip.ui-widget {
    display: none !important;
}

.up-first {
  text-transform: capitalize;
}

.catalog-list {
  counter-reset: li;
  list-style: outside none none;
  padding-left: 15px;
}

.margin-bottom-5 {
  margin-bottom: 7px;
}

.metatags .edit-btn {
  padding-left: 0;
}

.metatags .edit-input {
  margin-bottom: 10px;
}

.image-manager .thumbnail img {
  width: 100%;
  max-height: 200px;
}

.thumbnail .caption {
  padding: 3px 0 0 0;
  position: relative;
  display: flow-root;
  color: #333;
}
